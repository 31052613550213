module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Taylor Radtke | A Creative Mind","short_name":"taylorradtke","description":"Hi, I’m Taylor Radtke — a Full Stack Developer and Designer with a passion for crafting analytical, elegant solutions to complex challenges. I specialize in transforming ideas into sleek, scalable software, writing maintainable code that’s as thoughtful for future developers as it is for end-users. My approach prioritizes accessible, user-focused design, ensuring every solution is inclusive and intuitive.-split-I thrive in fast-paced environments, excelling under pressure and adeptly managing multiple priorities while maintaining the highest standards of quality. My goal is to contribute to a collaborative, innovative team where I can leverage my skills to build impactful, forward-thinking applications.","start_url":"/","background_color":"#fff","theme_color":"#c0a062","display":"minimal-ui","icon":"src/images/favicons/mstile-144x144.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"722b6720e2e5fec869706c049d39a63e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-115162174-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
